.documents {
  background-color: white;
  margin-top: 100px;
  padding-top: 40px;
  display: flex;
  flex-wrap: wrap;
  overflow-x: scroll;
  overflow-y: hidden;
  box-shadow: 10px 5px 40px rgba(0,0,0,.45);
}
.documents-wrap {
  padding: 5rem 7rem 5rem 7rem;
}
.tagcloud {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  border-top: 1px solid black;
  border-bottom: 1px solid black;
  margin: 50px 50px 100px 50px;
}
.tagcloud a {
  color: white;
}
.category {
  margin: 20px;
  text-align: center;
}
.category h1 {
  font-size: 1.5rem;
  font-family: "Domine";
}
.documents-table {
  width: 100%;
}
td {
  padding: 0 20px;
}
.column-title h2 {
  display: inline;
  margin-right: 10px;
  display: inline;
  margin-right: 10px;
  font-family: "helvetica", sans-serif;
  font-weight: normal;
  text-transform: uppercase;
  letter-spacing: 2px;
  font-size: 14px;
  color: #646464;
}
.col-year {
  width: 75px;
}
.col-title {
  width: 250px;
  text-align: center;
}
.col-authors {
  width: 75px;
}
.col-added {
  width: 100px;
}
.column_header {
  border-bottom: 1px solid gray;
}
.fa-sort {
  cursor: pointer;
}
.doc-marker h3 {
  font-weight: normal;
}
.disclaimer {
  width: 80%;
  text-align: center;
}
.legal {
  text-align: center;
  font-size: .8rem;
  color: rgba(0, 0, 0, 0.6);
  margin-bottom: 10px;
  margin-top: 20px;
}
.selected-tag .fa-times-circle-o {
  font-size: 20px;
  cursor: pointer;
  float: right;
  padding-left: 8px;
  padding-right: 0;
  opacity: 0.5;
}
.selected-tag .fa-times-circle-o:hover {
  opacity: 1;
}
.reset-button .button {
  margin-top: 0;
  padding-left: 100px;
  padding-right: 100px;
  color: white;
  background: #545C75;
}
.reset-button {
  width: 100%;
  margin-top: 50px;
  margin-bottom: 100px;
}
option {
  font-family: "Helvetica";
}
.current-tags {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
  width: 80%;
}
.selected-tag {
  padding: 10px;
  margin-top: 15px;
  margin-right: 15px;
  color: white;
  text-align: center;
  background-color: rgba(61, 99, 68, 0.6);
  box-shadow: 10px 5px 40px rgba(0,0,0,.45);
}
.tags-title {
  width: 100%;
  text-align: center;
}
.tags-title h1 {
  text-transform: uppercase;
  font-family: "helvetica";
  letter-spacing: 2px;
  font-weight: normal;
}
@media screen and (max-width: 1024px) {
  .documents-wrap {
    padding: 2rem 1rem 0 1rem;
  }
  .documents-page-title {
    font-size: 3rem;
  }
}
@media screen and (max-width: 640px) {
  .documents-wrap {
    padding: 0 0 0 0;
  }
}
